import { Subject, BehaviorSubject } from 'rxjs';

class PushNotifications {
  constructor() {
    this.messages = new Subject();
    this.tokenSubject = new BehaviorSubject(null);
  }

  configure() {
    window.onMessage = () => this.messages.next();
  }
}

export default new PushNotifications();
