import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {
  FaInfoCircle, FaBell, FaQuestionCircle, FaCog,
} from 'react-icons/fa';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import {
  HashRouter, Switch, Route, Redirect, useHistory,
} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import logo from '../images/logo.png';
import Api from '../Api';

const LoginScreen = lazy(() => import('../LoginScreen/LoginScreen'));
const NotificationScreen = lazy(() => import('../NotificationScreen/NotificationScreen'));
const ReportScreen = lazy(() => import('../ReportScreen/ReportScreen'));
const AboutScreen = lazy(() => import('../AboutScreen/AboutScreen'));
const TutorialScreen = lazy(() => import('../TutorialScreen/TutorialScreen'));
const SettingsScreen = lazy(() => import('../SettingsScreen/SettingsScreen'));

const StyledBottomNavigation = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
}))(BottomNavigation);

const SettingsIcon = () => {
  const history = useHistory();
  return history.location.pathname === '/login' ? null : (
    <FaCog
      onClick={() => history.push('/settings')}
      size={16}
    />
  );
};

const AppHeader = () => (
  <AppBar color="default">
    <Toolbar classes={{ root: 'toolbar' }}>
      <Box padding={1}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <img
              alt="Med 127"
              height="32px"
              src={logo}
            />
          </Grid>
          <Grid item xs={1}>
            <SettingsIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" paragraph>
              Corventive Health is a HIPAA compliant application that enables care coordination between the
              cardiology and emergency medicine clinical teams.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Toolbar>
  </AppBar>
);

const AppBottomNavigation = () => {
  const history = useHistory();
  return (
    history.location.pathname === '/login' ? null : (
      <StyledBottomNavigation>
        <BottomNavigationAction
          icon={<FaBell color="white" size={16} />}
          onClick={() => history.push('/notifications')}
        />
        <BottomNavigationAction
          icon={<FaQuestionCircle color="white" size={16} />}
          onClick={() => history.push('/tutorial')}
        />
        <BottomNavigationAction
          icon={<FaInfoCircle color="white" size={16} />}
          onClick={() => history.push('/about')}
        />
      </StyledBottomNavigation>
    )
  );
};

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: blue,
    text: {
      primary: blue[900],
      secondary: blue[500],
    },
  },
});

const BaseRouteContent = () => (
  Api.isAuthorized()
    ? <Redirect to="/notifications" />
    : <Redirect to="/login" />
);

const LoginRouteContent = () => (
  Api.isAuthorized()
    ? <Redirect to="/notifications" />
    : <LoginScreen />
);

const NotificationsRouteContent = () => (
  Api.isAuthorized()
    ? <NotificationScreen />
    : <Redirect to="/login" />
);

const ReportRouteContent = ({ match }) => (
  Api.isAuthorized()
    ? <ReportScreen sessionId={match.params.sessionId} />
    : <Redirect to="/login" />
);

const AboutRouteContent = () => (
  Api.isAuthorized()
    ? <AboutScreen />
    : <Redirect to="/login" />
);

const TutorialRouteContent = () => (
  Api.isAuthorized()
    ? <TutorialScreen />
    : <Redirect to="/login" />
);

const SettingsRouteContent = () => (
  Api.isAuthorized()
    ? <SettingsScreen />
    : <Redirect to="/settings" />
);

ReportRouteContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sessionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const App = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  useEffect(() => (
    Api.errors.subscribe(
      (error) => {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      },
    ).unsubscribe
  ), []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<CircularProgress />}>
        <HashRouter>
          <AppHeader />
          <Box height="calc(100vh - 196px)">
            <Box
              display="flex"
              flexDirection="column"
              marginTop={24}
              height={1}
            >
              <Box
                flexGrow={1}
                padding={2}
              >
                <Switch>
                  <Route
                    component={BaseRouteContent}
                    exact
                    path="/"
                  />
                  <Route
                    component={LoginRouteContent}
                    path="/login"
                  />
                  <Route
                    component={NotificationsRouteContent}
                    path="/notifications"
                  />
                  <Route
                    component={ReportRouteContent}
                    path="/report/:sessionId"
                  />
                  <Route
                    component={AboutRouteContent}
                    path="/about"
                  />
                  <Route
                    component={TutorialRouteContent}
                    path="/tutorial"
                  />
                  <Route
                    component={SettingsRouteContent}
                    path="/settings"
                  />
                </Switch>
              </Box>
              <Box
                display="fixed"
                bottom={0}
              >
                <AppBottomNavigation />
              </Box>
            </Box>
          </Box>
          <Snackbar
            autoHideDuration={5000}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              elevation={6}
              severity="error"
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </HashRouter>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
