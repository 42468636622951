import axios from 'axios';
import { Subject, BehaviorSubject } from 'rxjs';

class Api {
  constructor() {
    const token = localStorage.getItem('token');
    this.tokenSubject = new BehaviorSubject(token);
    this.root = process.env.API_ROOT;
    this.errors = new Subject();
  }

  getAuthOptions() {
    return {
      headers: {
        Authorization: `JWT ${this.tokenSubject.getValue()}`,
      },
    };
  }

  handleError(error) {
    const { response } = error;
    this.errors.next(error);
    switch (response.status) {
      case 401:
        this.logout();
        break;
      default:
    }
    throw error;
  }

  login(body) {
    return axios.post(`${this.root}/cardiologist-login/`, body)
      .then(({ data }) => {
        if ('token' in data) {
          localStorage.setItem('token', data.token);
          this.tokenSubject.next(data.token);
        }
      })
      .catch((error) => this.handleError(error));
  }

  logout() {
    localStorage.removeItem('token');
    this.tokenSubject.next(null);
  }

  isAuthorized() {
    return this.tokenSubject.getValue() !== null;
  }

  getNotifications() {
    return axios.get(`${this.root}/notifications/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getSessions() {
    return axios.get(`${this.root}/sessions/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getPatients() {
    return axios.get(`${this.root}/patients/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getFacility(id) {
    return axios.get(`${this.root}/facilities/${id}/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  generateReport(sessionId, reportData) {
    return axios.post(`${this.root}/sessions/${sessionId}/report/`, reportData, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  generateDatapoint(sessionId, datapointData) {
    return axios.post(`${this.root}/sessions/${sessionId}/datapoint/`, datapointData, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getProfile() {
    return axios.get(`${this.root}/profile/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  saveProfile(profile) {
    return axios.post(`${this.root}/profile/`, profile, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getPractice() {
    return axios.get(`${this.root}/practice/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getDevices() {
    return axios.get(`${this.root}/devices/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  updateDevice(id, key, token) {
    return axios.put(`${this.root}/devices/${id}/`, { [key]: token }, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createDevice(key, token) {
    return axios.post(`${this.root}/devices/`, { [key]: token }, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  updateServerToken(token) {
    const type = navigator.userAgent.includes('Android') ? 'web' : 'ios';
    const key = `${type}_token`;
    return this.getDevices()
      .then((devices) => (
        devices.length === 0
          ? this.createDevice(key, token)
          : this.updateDevice(devices[0].id, key, token)
      ))
      .then((data) => data[key]);
  }
}

export default new Api();
