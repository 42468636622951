import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import PushNotifications from './PushNotifications';
import Messenger from './Messenger';

ReactDOM.render(<App />, document.getElementById('root'));
navigator.serviceWorker.register('service-worker.js');
window.onerror = (error) => Messenger.sendMessage({
  type: 'log_data',
  data: {
    level: 'error',
    message: error.message,
  },
});
PushNotifications.configure();
