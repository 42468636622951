class Messenger {
  constructor() {
    this.channel = 'MessageChannel' in window
      ? window.MessageChannel
      : null;
  }

  sendMessage(data) {
    if (
      this.channel !== null
      && 'postMessage' in this.channel
    ) {
      const message = JSON.stringify(data);
      this.channel.postMessage(message);
    }
  }
}

export default new Messenger();
